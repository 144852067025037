<template>
    <a-layout>
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
            <mymenu />
        </a-layout-sider>
        <a-layout>
            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
                支付成功
                {{ this.$route.query }} <br>
                paymentId = {{ payment_id }}<br>
                token = {{ token }}<br>
                PayerID = {{ PayerID }}<br>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import menu from "./menu.vue";
export default {
    components: {
        mymenu: menu
    },
    data() {
        return {
            key: "value",
            payment_id: this.$route.query.paymentId,
            token: this.$route.query.token,
            PayerID: this.$route.query.PayerID,
        }
    },
    methods: {
        surebuy(){
            var data = {"payment_id": this.payment_id, "payer_id": this.PayerID}

            this.myaxios("http://127.0.0.1:8000/paypal/sure/", "post", data).then((res) => {
                console.log(res);
            }).catch((err) => {
                console.log(err);
            });
        },
    },
    mounted() {
        
    },
    created() { this.surebuy();}
}
</script>

<style scoped>
@import url('../assets/style.css');
</style>